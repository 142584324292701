import { type PropsWithChildren, createContext, useContext } from 'react';
import type { AnyHTTPClient } from '../react-query';

export function createServiceHTTPClientProvider<Client extends AnyHTTPClient>({
  name,
}: {
  name: string;
}) {
  const Context = createContext(null as unknown as Client);

  function ServiceHTTPClientProvider({
    client,
    children,
  }: PropsWithChildren<{ client: Client }>) {
    return <Context.Provider value={client}>{children}</Context.Provider>;
  }

  ServiceHTTPClientProvider.displayName = `${name}HTTPClientProvider`;

  function useServiceHTTPClient() {
    const client = useContext(Context);

    if (!(client as Client | null)) {
      throw new Error(
        `use${name}HTTPClient must be called inside of ${ServiceHTTPClientProvider.displayName}`,
      );
    }

    return client;
  }

  return [ServiceHTTPClientProvider, useServiceHTTPClient] as const;
}
