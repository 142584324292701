import type { createHTTPClient } from '@medizzy/http-client';
import { createServiceHTTPClientProvider } from '@medizzy/service-utils';
import {
  createBoundServiceMutationHookCreator,
  createBoundServiceQueryHooksCreator,
} from '@medizzy/service-utils/react-query';
import { type ZodSchema, z } from 'zod';

export const [HTTPClientProvider, useHTTPClient] =
  createServiceHTTPClientProvider<ReturnType<typeof createHTTPClient>>({
    name: 'API',
  });

export const createAPIQueryHooks = createBoundServiceQueryHooksCreator({
  queryKeyPrefix: 'medizzy-api',
  useClient: useHTTPClient,
});

export const createAPIMutationHook = createBoundServiceMutationHookCreator({
  useClient: useHTTPClient,
});

export const paginationRequestParams = {
  page: z.number().nonnegative().optional(),
  size: z.number().nonnegative().optional(),
};

export function paginatedSchema<T extends ZodSchema>(content: T) {
  return z.object({
    page: z.object({
      number: z.number(),
      size: z.number(),
      totalElements: z.number(),
      totalPages: z.number(),
    }),
    content: z.array(content),
  });
}

export type PaginatedResponse<T> = {
  page: {
    number: number;
    size: number;
    totalElements: number;
    totalPages: number;
  };
  content: T[];
};
