import {
  type QueryKey,
  type UseQueryOptions,
  type UseSuspenseQueryOptions,
  useQuery,
  useSuspenseQuery,
} from '@tanstack/react-query';
import type {
  AnyHTTPClient,
  HasParams,
  ServiceMethodParams,
  UseHTTPClientHook,
  UseServiceMethodError,
  VoidType,
} from './types';

type ServiceQueryMethod<Client extends AnyHTTPClient, Result, Params = void> = (
  args: ServiceMethodParams<Client, Params>,
) => Result | Promise<Result>;

type UseServiceQueryOptions<Result, Params = void> = Omit<
  UseQueryOptions<Result, UseServiceMethodError>,
  'queryFn' | 'queryKey'
> &
  (HasParams<Params> extends true ? { params: Params } : object);

type UseSuspenseServiceQueryOptions<Result, Params = void> = Omit<
  UseSuspenseQueryOptions<Result, UseServiceMethodError>,
  'queryFn' | 'queryKey'
> &
  (HasParams<Params> extends true ? { params: Params } : object);

type GetQueryKeyOptions<Params = void> = HasParams<Params> extends true
  ? { params: Params }
  : VoidType;

export interface CreateServiceQueryHooksParams<
  Client extends AnyHTTPClient,
  Result,
  Params = void,
> {
  queryFn: ServiceQueryMethod<Client, Result, Params>;
  queryKey: QueryKey;
  useClient: UseHTTPClientHook<Client>;
}

/**
 * Function for creating service `@tanstack/react-query` `useQuery` hooks.
 * It returns a tuple with 2 hooks - `[useQuery, useSuspenseQuery]`, which
 * will call the passed `queryFn`, passing to it a client instance returned by
 * the `useClient` hook. The `queryFn` must take arguments in the form of
 * {@link ServiceMethodParams}.
 *
 * If the service method requires params, then the resulting hooks will also
 * require the user to pass them in the hook call. Otherwise, the options
 * param will be typed as optional. The params will be **automatically appended
 * to the `queryKey` array**.
 *
 * The hooks will have attached properties:
 * - `useQueryFn({ params, client })` - which will return the underlying `queryFn`
 * - `useQueryKey({ params })` - which will return the underlying `queryKey`
 *
 * You can use these properties when prefetching data using the QueryClient.
 *
 * ### Example
 *
 * ```ts
 * function fetchClaim({ params, client }: ServiceMethodParams<WebappHTTPClient, FetchClaimParams>) {
 *   return client.get(`/claims/${params.id}`).json();
 * }
 *
 * const [useClaim, useSuspenseClaim] = createServiceQueryHooks({
 *   queryFn: fetchClaim,
 *   queryKey: ['claim'],
 *   useClient: useWebappHTTPClient
 * });
 *
 * function loader() {
 *  const claim = await queryClient.fetchQuery({
 *    queryFn: useClaim.getQueryFn({ params, client }),
 *    queryKey: useClaim.getQueryKey({ params }),
 *  });
 *
 *  return claim;
 * }
 *
 * function Component() {
 *   const { data } = useSuspenseClaim({ params: { id: 100 }})
 *   return <pre>{JSON.stringify(data, null, 2)}</pre>
 * }
 * ```
 *
 * @param queryFn - Service method with params in the form of {@link ServiceMethodParams}
 * @param queryKey - `@tanstack/react-query` query key
 * @param useClient - hook returning an instance of `HTTPClient`
 * @returns `[useQuery, useSuspenseQuery]` - a tuple containing `@tanstack/react-query` hooks
 */
export function createServiceQueryHooks<
  Client extends AnyHTTPClient,
  Result,
  Params = void,
>({
  queryFn,
  queryKey,
  useClient,
}: CreateServiceQueryHooksParams<Client, Result, Params>) {
  function getQueryFn(options: ServiceMethodParams<Client, Params>) {
    return () => queryFn(options);
  }

  function getQueryKey(options: GetQueryKeyOptions<Params>) {
    return typeof options?.params !== 'undefined'
      ? [...queryKey, options.params]
      : queryKey;
  }

  function useServiceQuery(
    options: HasParams<Params> extends true
      ? UseServiceQueryOptions<Result, Params>
      : UseServiceQueryOptions<Result> | VoidType,
  ) {
    const client = useClient();
    const params =
      typeof options !== 'undefined'
        ? (options as { params?: Params }).params
        : undefined;

    return useQuery({
      ...options,
      queryFn: getQueryFn({ params, client } as ServiceMethodParams<
        Client,
        Params
      >),
      queryKey: getQueryKey({ params } as GetQueryKeyOptions<Params>),
    });
  }

  useServiceQuery.getQueryFn = getQueryFn;
  useServiceQuery.getQueryKey = getQueryKey;

  function useSuspenseServiceQuery(
    options: HasParams<Params> extends true
      ? UseSuspenseServiceQueryOptions<Result, Params>
      : UseSuspenseServiceQueryOptions<Result> | VoidType,
  ) {
    const client = useClient();
    const params =
      typeof options !== 'undefined'
        ? (options as { params?: Params }).params
        : undefined;

    return useSuspenseQuery({
      ...options,
      queryFn: getQueryFn({ params, client } as ServiceMethodParams<
        Client,
        Params
      >),
      queryKey: getQueryKey({ params } as GetQueryKeyOptions<Params>),
    });
  }

  useSuspenseServiceQuery.getQueryFn = getQueryFn;
  useSuspenseServiceQuery.getQueryKey = getQueryKey;

  return [useServiceQuery, useSuspenseServiceQuery] as const;
}

/**
 * Lets you create a bound service query creator, which means all hooks returned
 * by this creator will use the predefined `useClient` hooks as well as the
 * provided `queryKeyPrefix`.
 *
 * ### Example
 *
 * ```ts
 * const createWebappQueryHooks = createBoundServiceQueryHooksCreator({
 *   useClient: useWebappHTTPClient,
 *   queryKeyPrefix: 'webapp'
 * })
 *
 * const [useClaim, useSuspenseClaim] = createWebappQueryHooks({
 *   queryFn: fetchClaim,
 *   queryKey: ['claim']
 * });
 * ```
 *
 * @param queryKeyPrefix - Prefix used in all created hooks' `queryKey` param
 * @param useClient - Hook returning an instance of HTTPClient
 */
export function createBoundServiceQueryHooksCreator<
  Client extends AnyHTTPClient,
>({
  useClient,
  queryKeyPrefix,
}: {
  queryKeyPrefix: string;
  useClient: UseHTTPClientHook<Client>;
}) {
  function createBoundServiceQueryHooks<Result, Params = void>({
    queryFn,
    queryKey,
  }: Pick<
    CreateServiceQueryHooksParams<Client, Result, Params>,
    'queryFn' | 'queryKey'
  >) {
    return createServiceQueryHooks({
      queryFn,
      queryKey: [queryKeyPrefix, ...queryKey],
      useClient,
    });
  }

  return createBoundServiceQueryHooks;
}
