import { type UseMutationOptions, useMutation } from '@tanstack/react-query';
import type {
  AnyHTTPClient,
  ServiceMethodParams,
  UseHTTPClientHook,
  UseServiceMethodError,
  VoidType,
} from './types';

type ServiceMutationMethod<
  Client extends AnyHTTPClient,
  Result,
  Params = void,
> = (args: ServiceMethodParams<Client, Params>) => Promise<Result>;

export interface CreateServiceMutationHookParams<
  Client extends AnyHTTPClient,
  Result,
  Params = VoidType,
> {
  mutationFn: ServiceMutationMethod<Client, Result, Params>;
  useClient: UseHTTPClientHook<Client>;
}

/**
 * Function for creating service `@tanstack/react-query` `useMutation` hooks.
 * It returns a hook, which will call the passed `mutationFn` passing to it
 * params and a client instance returned by the `useClient` hook. The
 * `mutationFn` must take arguments in the form of {@link ServiceMethodParams}
 *
 * Params defined on the service method will be inferred by the `mutate` /
 * `mutateAsync` methods.
 *
 * ### Example
 *
 * ```ts
 * function createUser(
 *  { client, params }: ServiceMethodParams<WebappHTTPClient, CreateUserParams}
 * ) {
 *  return client.url('/users').post(params).json()
 * }
 *
 * const useCreateUser = createServiceMutationHook({
 *   mutationFn: createUser,
 *   useClient: useWebappHTTPClient
 * })
 * ```
 *
 * @param mutationFn - Service method
 * @param useClient - Hook returning an instance of HTTPClient
 */
export function createServiceMutationHook<
  Client extends AnyHTTPClient,
  Result,
  Params = void,
>({
  mutationFn,
  useClient,
}: CreateServiceMutationHookParams<Client, Result, Params>) {
  function useServiceMutation(
    options?: Omit<
      UseMutationOptions<Result, UseServiceMethodError, Params>,
      'mutationFn'
    >,
  ) {
    const client = useClient();

    return useMutation({
      ...options,
      mutationFn: (params) =>
        mutationFn({ params, client } as ServiceMethodParams<Client, Params>),
    });
  }

  return useServiceMutation;
}

/**
 * Lets you create a bound service mutation creator, which means all hooks
 * returned by this creator will use the predefined `useClient` hook.
 *
 * ### Example
 *
 * ```ts
 * const createWebappMutationHook = createBoundServiceMutationHookCreator({
 *   useClient: useWebappHTTPClient
 * })
 *
 * const useCreateUser = createWebappMutationHook({
 *   mutationFn: createUser
 * })
 * ```
 *
 * @param useClient - Hook returning an instance of HTTPClient
 */
export function createBoundServiceMutationHookCreator<
  Client extends AnyHTTPClient,
>({ useClient }: { useClient: UseHTTPClientHook<Client> }) {
  function createBoundServiceMutationHook<Result, Params = void>({
    mutationFn,
  }: Omit<
    CreateServiceMutationHookParams<Client, Result, Params>,
    'useClient'
  >) {
    return createServiceMutationHook<Client, Result, Params>({
      useClient,
      mutationFn,
    });
  }

  return createBoundServiceMutationHook;
}
